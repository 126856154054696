import { graphql } from 'gatsby';
import React, { useState } from 'react';
import Layout from '~/components/Layout';
import HeroHeading from '~/components/organisms/HeroHeading';
import ContactForm from '~/components/organisms/ContactForm';
import TwoColumns from '~/components/organisms/TwoColumns';
import Ctas from '~/components/organisms/Ctas';
import SEO from 'gatsby-plugin-wpgraphql-seo';

export default function Contact({ data: { wpPage } }) {

  const page = wpPage.contact.contact
  const hero = page.hero

  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <Layout>
      <SEO post={wpPage} />
      <HeroHeading {...hero}  className="!bg-transparent !mb-0 md:!mb-0" />
      <ContactForm isSubmitted={isSubmitted} setIsSubmitted={setIsSubmitted} />
      <TwoColumns {...wpPage.contact.contact} />
      <Ctas {...wpPage.contact.contact.ctas} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query contactPageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      id
      ...SEO
      contact {
        contact {
          twoColumns {
            textCard {
              illustration {
                ... Image
              }
              subheading
              subheadingColor
              alignText
              heading {
                text
                type
              }
              content
              buttons {
                button {
                  button {
                    button {
                      ... Button
                    }
                  }
                  type
                }
              }
            }
            secondImage {
              ... Image
            }
            thirdImage {
              ... Image
            }
            image {
              ... Image
            }
            backgroundColour {
              colour
            }
            alignImage
            alignSecondImage
          }
          hero {
            textCard {
              illustration {
                ... Image
              }
              subheading
              subheadingColor
              alignText
              heading {
                text
                type
              }
              content
              buttons {
                button {
                  button {
                    button {
                      ... Button
                    }
                  }
                  type
                }
              }
            }
          }
          ctas {
            socialsCta {
              textCard {
                illustration {
                  ... Image
                }
                subheading
                subheadingColor
                alignText
                heading {
                  text
                  type
                }
                content
                buttons {
                  button {
                    button {
                      button {
                        ... Button
                      }
                    }
                    type
                  }
                }
              }
            }
            resourcesCta {
              textCard {
                illustration {
                  ... Image
                }
                subheading
                subheadingColor
                alignText
                heading {
                  text
                  type
                }
                content
                buttons {
                  button {
                    button {
                      button {
                        ... Button
                      }
                    }
                    type
                  }
                }
              }
            }

          }
        }
      }
    }
  }
`
