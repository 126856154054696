import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import InputField from '~/components/atoms/InputField';
// import SelectField from '~/components/atoms/SelectField';
import TextareaField from '~/components/atoms/TextareaField';
import CheckboxField from '~/components/atoms/CheckboxField';
import Button from '~/components/atoms/Button';
import useFormSubmit from '~/hooks/useFormSubmit';
import FadeInWhenVisible from '~/components/FadeInWhenVisible';

const validationSchema = Yup.object({
  firstname: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  mobilephone: Yup.string().required('Required'),
  your_message: Yup.string().required('Required'),
  privacy: Yup.boolean().oneOf([true], 'This field must be checked'),
});

export default function ContactForm({ isSubmitted, setIsSubmitted }) {
  const { post, loading, response } = useFormSubmit({
    portalId: '7026924',
    formId: '2deb4350-6346-4675-8694-ec1b9dbbd2d9',
    setIsSubmitted,
  });

  const [role, setRole] = useState('');

  return (
    <FadeInWhenVisible className={`mb-20 md:mb-36`}>
      <div className="container sm:px-10">
        <div className="relative px-8 py-16 mb-32 md:py-24 md:pb-28 sm:px-10 bg-sand">
        {!isSubmitted ? (
          <Formik
            initialValues={{
              firstname: '',
              marketing_contact_type: 'resident',
              email: '',
              mobilephone: '',
              your_message: '',
              privacy: false,
            }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {

              delete values['privacy'];

              await post(values);
              if (setIsSubmitted) {
                setIsSubmitted(true);
              }
            }}
          >
            {(props) => (
              <Form id="contact-form" className="grid max-w-md grid-cols-12 mx-auto gap-y-8 md:gap-y-12">
                {/* <pre>{JSON.stringify(props, null, 4)}</pre> */}
                <div className="flex col-span-12 text-h4">
                  I'm a{' '}
                  <div className="relative border-b-4 border-b-yellow ml-2.5 pr-8 heading-select">
                    {props.values.marketing_contact_type.replace('_', ' ')}
                    <select
                      className="absolute inset-0 z-10 block w-full pr-8 text-base bg-transparent outline-none opacity-0 cursor-pointer heading-select"
                      name="marketing_contact_type"
                      onChange={(e) => props.setFieldValue('marketing_contact_type', e.target.value)}
                    >
                      <option value="resident">resident</option>
                      <option value="property_owner">property owner</option>
                    </select>
                  </div>
                </div>
                <InputField divclassname="col-span-12 " name="firstname" type="text" label="Your full name" />
                <InputField divclassname="col-span-12 " name="email" type="email" label="Your email address" />
                <InputField divclassname="col-span-12 " name="mobilephone" type="text" label="Your phone number" />
                <TextareaField
                  divclassname="col-span-12 "
                  name="your_message"
                  type="textarea"
                  label="How can we help?"
                />

                <CheckboxField
                  divclassname="col-span-12"
                  name="privacy"
                  label={`By submitting this form, you accept our <a href="/privacy-policy">Privacy Policy</a>`}
                />
                <div className="col-span-12">
                  <Button title="Send Enquiry" className="w-full" type="submit" />
                </div>
              </Form>
            )}
          </Formik>
        ) : (
           <div className="max-w-lg mx-auto text-center">
            <StaticImage
              src={'../../assets/images/form-success.png'}
              objectFit="contain"
              objectPosition="center"
              alt=""
              className="w-40 mb-8"
            />
            <h2 className="mb-4 text-h3">Got it!</h2>
            <p className="max-w-xs mx-auto">
              We received your request and someone will follow up in the next 1-2 business days.
            </p>
          </div>
        )}

          <div data-scroll data-scroll-speed="-.5" className="w-20 hidden md:block !absolute -top-8 right-20">
            <StaticImage
              src={'../../assets/images/contact-illustration-1.png'}
              objectFit="contain"
              objectPosition="center"
              alt=""
            />
          </div>
          <div data-scroll data-scroll-speed="2" className="w-14 hidden md:block !absolute top-1/2 -left-4"></div>
          <StaticImage
            src={'../../assets/images/contact-illustration-2.png'}
            objectFit="contain"
            objectPosition="center"
            alt=""
          />
          <div
            data-scroll
            data-scroll-speed="-.5"
            className="w-16 hidden md:block !absolute bottom-1/4 md:right-8 lg:right-0"
          >
            <StaticImage
              src={'../../assets/images/contact-illustration-3.png'}
              objectFit="contain"
              objectPosition="center"
              alt=""
            />
          </div>
        </div>
      </div>
    </FadeInWhenVisible>
  );
}
